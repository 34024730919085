<template>
  <div class="bt-cif pa-5">
    <Header title="Pembukaan Rekening" titleIcon="mdi-note-plus-outline" class="mb-4" />
    <v-container class="pa-0">
      <div class="">
        <div class="w-full white elevation-3 rounded-lg pa-3 mb-3">
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select color="black" autocomplete="off" outlined :items="list.hari_transaksi"
                  v-model="form.hari_transaksi" label="Hari Transaksi" required
                  :rules="[(v) => !!v || 'Hari Transaksi is required']" />
                <v-select color="black" autocomplete="off" outlined :items="list.cm" v-model="form.cm_code"
                  label="Rembug" required :rules="[(v) => !!v || 'Rembug is required']" />
                <v-select color="black" autocomplete="off" outlined :items="list.cif" v-model="form.cif_no"
                  label="ID Anggota" required :rules="[(v) => !!v || 'ID Anggota is required']" />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.nama"
                  label="Nama Lengkap (Sesuai KTP)" disabled />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.tgl_lahir" label="Tanggal Lahir"
                  disabled />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.usia" label="Usia" disabled />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.no_ktp" label="No KTP" disabled />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.no_hp" label="No HP" disabled />
                <v-textarea color="black" autocomplete="off" outlined v-model="form.alamat" label="Alamat" disabled
                  rows="3" />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.rt_rw" label="RT/RW" disabled />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.desa" label="Desa" disabled />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.kecamatan" label="Kecamatan"
                  disabled />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.kabupaten" label="Kabupaten"
                  disabled />
                <v-select color="black" autocomplete="off" outlined :items="list.produk" v-model="form.kode_produk"
                  label="Produk Tabungan" required :rules="[(v) => !!v || 'Produk Tabungan is required']"
                  @change="setTabungan()" />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.setoran" label="Setoran"
                  v-mask="thousandMask" />
                <v-select color="black" autocomplete="off" outlined v-model="form.periode_setoran"
                  label="Periode Setoran" required :rules="[(v) => !!v || 'Periode Setoran is required']"
                  :items="list.periode_setoran" />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.jangka_waktu"
                  label="Jangka Waktu" />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.rencana_awal_setoran" type="date"
                  label="Rencana Awal Setoran" />
                <v-text-field color="black" autocomplete="off" outlined v-model="form.tgl_buka" type="date"
                  label="Tanggal Pembukaan" />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-row>
        <v-col cols="6" class="pb-0">
          <router-link to="/tabungan/dashboard">
            <v-btn block class="purple lighten-1 white--text">Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn block class="purple lighten-1 white--text" @click="doSave()" :disabled="!isFormValid">
            Simpan
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "@/components/Header";
import Camera from "@/components/Camera.vue";
export default {
  name: "Tabungan",
  components: {
    Toast,
    Header,
    Camera
  },
  data() {
    return {
      list: {
        hari_transaksi: [
          {
            value: "1",
            text: "Senin",
          },
          {
            value: "2",
            text: "Selasa",
          },
          {
            value: "3",
            text: "Rabu",
          },
          {
            value: "4",
            text: "Kamis",
          },
        ],
        cm: [],
        cif: [],
        produk: [],
        periode_setoran: [
          {
            value: 0,
            text: "Harian",
          },
          {
            value: 1,
            text: "Mingguan",
          },
          {
            value: 2,
            text: "Bulanan",
          },
        ]
      },
      form: {
        hari_transaksi: null,
        cm_code: null,
        cif_no: null,
        kode_produk: null,
        setoran: 0,
        periode_setoran: null,
        jangka_waktu: 0,
        rencana_awal_setoran: null,
        tgl_buka: null
      },
      isFormValid: true,
      alert: {
        show: false,
        msg: "",
      },
      overlay: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    "form.hari_transaksi": {
      handler: function (newValue) {
        if (newValue) {
          this.getInfoRembug();
        }
      },
      immediate: true,
    },
    "form.cm_code": {
      handler: function (newValue) {
        if (newValue) {
          this.getInfoMember();
        }
      },
      immediate: true,
    },
    "form.cif_no": {
      handler: function (newValue) {
        if (newValue) {
          this.setInfoMember();
          this.getProduk();
        }
      },
      immediate: true,
    },
    overlay: {
      handler: function (newValue) {
        if (newValue) {
          this.overlay = true;
        } else {
          this.overlay = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...helper,
    async getInfoRembug() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("hari_transaksi", this.form.hari_transaksi);
      try {
        const req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const cm =
              req.data.data.map((item) => {
                return {
                  value: item.cm_code,
                  text: item.cm_name,
                };
              }) || [];
            this.list.cm = cm;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async getInfoMember() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("cm_code", this.form.cm_code);
      try {
        const req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          const cif =
            req.data.data.map((item) => {
              return {
                value: item.cif_no,
                text: item.nama,
              };
            }) || [];

          this.list.cif = cif;
          this.list.cifs = req.data.data || [];
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async setInfoMember() {
      const row = this.list.cifs.find(
        (item) => item.cif_no === this.form.cif_no
      );
      Object.assign(this.form, {
        branch_code: row.branch_code || null,
        cif_no: row.cif_no || null,
        nama: row.nama || null,
        tgl_lahir: row.tgl_lahir || null,
        usia: row.usia || null,
        no_ktp: row.no_ktp || null,
        no_hp: row.no_hp || null,
        alamat: row.alamat || null,
        rt_rw: row.rt_rw || null,
        desa: row.desa || null,
        kecamatan: row.kecamatan || null,
        kabupaten: row.kabupaten || null,
        jenis_usaha: row.jenis_usaha || null,
      });
    },
    async getProduk() {
      let payload = new FormData();
      payload.append("cif_no", this.form.cif_no);
      this.list.produk = [];
      try {
        let req = await services.produkTabungan(payload, this.user.token);
        if (req.status === 200) {
          req.data.data.map((item) => {
            this.list.produk.push({
              value: item.product_code,
              text: item.product_name,
              data: item,
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async setTabungan() {
      let produk = this.list.produk.find(
        (item) => item.value == this.form.kode_produk
      ).data;
      this.form.jenis_tabungan = produk.product_type;
      this.form.created_by = this.user.fa_code;
    },
    async doSave() {
      let payload = new FormData();
      payload.append('product_code', this.form.kode_produk);
      payload.append('cif_no', this.form.cif_no);
      payload.append('branch_code',  this.user.branch_code);
      payload.append('rencana_setoran', this.form.setoran);
      payload.append('rencana_periode_setoran', this.form.periode_setoran);
      payload.append('rencana_jangka_waktu', this.form.jangka_waktu);
      payload.append('jenis_tabungan', this.form.jenis_tabungan);
      payload.append('rencana_setoran_next', this.form.rencana_awal_setoran);
      payload.append('tanggal_pembukaan', this.form.tgl_buka);
      payload.append('created_by', this.user.fa_code);

      try {
        let req = await services.registrasiTabungan(payload, this.user.token);
        if (req.data.status === true) {
          this.alert = {
            show: true,
            msg: "Registrasi Tabungan Berhasil",
          };
          setTimeout(() => {
            this.$router.push(`/tabungan`);
          }, 1500);
        } else {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    }
  }
};
</script>
